<template>
  <layout-with-menu :loading="loading">
    <div class="contract">
      <hero-header
        v-if="isHeroHeaderVisible"
        :currency="currency"
        :contracts="loans"
        :enablePrematurePaymentText="true"
      />
      <company-hero-header v-else-if="isCompanyHeroHeaderVisible" />
      <div v-else class="corporate-gap-when-hero-header-is-missing"/>
      <div class="contract__content">
        <horizontal-actions-menu
          class="contract__content__menu"
          v-bind="{activeTab, tabNames, tabRoutes}" />
        <router-view></router-view>
      </div>
    </div>
    <campaign-modal
      :visible="popupCampaignsVisible"
      :campaigns="popupCampaigns"
      @close="closeCampaignModal"
    ></campaign-modal>
  </layout-with-menu>
</template>

<script>
import HeroHeader from '@loan/components/HeroHeader.vue'
import CompanyHeroHeader from '@loan/components/CompanyHeroHeader.vue'
import HorizontalActionsMenu from '@loan/components/HorizontalActionsMenu.vue'
import CampaignModal from '@/components/CampaignModal.vue'
import CampaignModalMixin from '../../../mixins/campaignModalMixin'
import { mapState, mapActions } from 'pinia'
import DebtNotificationMixin from '../../../mixins/debtNotificationMixin'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

const tabNames = {
  active: 'loan-active',
  closed: 'loan-closed',
  transactions: 'loan-transactions'
}

export default {
  name: 'bb-contract',
  components: {
    HeroHeader,
    HorizontalActionsMenu,
    CampaignModal,
    CompanyHeroHeader
  },
  mixins: [CampaignModalMixin, DebtNotificationMixin],
  data () {
    return {
      tabNames
    }
  },
  computed: {
    ...mapState(useRootStore, {
      rootFeatureFlags: 'featureFlags',
      isCompany: 'isCompany',
      isMobile: 'isMobile',
      contractsCount: 'contractsCount',
      hasCountryStoppedIssuingLoans: 'hasCountryStoppedIssuingLoans'
    }),
    ...mapState(useLoanStore, ['featureFlags', 'loading', 'loans']),
    translations () {
      return {
        transactions: this.$pgettext('contract_tabs', 'Transactions'),
        activeContracts: this.$pgettext('contract_tabs', 'Active contracts'),
        closedContracts: this.$pgettext('contract_tabs', 'Closed contracts')
      }
    },
    isHeroHeaderVisible () {
      return !this.isCompany
    },
    isCompanyHeroHeaderVisible () {
      return this.isCompany && this.rootFeatureFlags.showCompanyLoansHeader
    },
    activeTab () {
      return this.$route.name
    },
    currency () {
      if (this.loans && this.loans.length) {
        return this.loans[0].currency
      }
      return ''
    },
    isActiveTabVisible () {
      if (this.hasCountryStoppedIssuingLoans) {
        const hasActiveContract = this.contractsCount?.activeContracts > 0

        return hasActiveContract
      }

      return true
    },
    tabRoutes () {
      return [
        {
          tab: tabNames.active,
          title: this.translations.activeContracts,
          active: this.activeTab === tabNames.active,
          visible: this.isActiveTabVisible,
          path: { name: tabNames.active }
        },
        {
          tab: tabNames.closed,
          title: this.translations.closedContracts,
          active: this.activeTab === tabNames.closed,
          visible: true,
          path: { name: tabNames.closed }
        },
        {
          tab: tabNames.transactions,
          title: this.translations.transactions,
          active: this.activeTab === tabNames.transactions,
          visible: this.featureFlags.enableTransactionsView,
          path: { name: tabNames.transactions }
        }
      ].filter(({ visible }) => visible)
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['init'])
  },
  async mounted () {
    await this.init()
  }
}
</script>

<style lang="scss">
.bb-horizontal-menu__link-content-wrapper {
  text-transform: initial;
}

.corporate-gap-when-hero-header-is-missing {
  height: 20px;

  @media (max-width: $mobile-view-breaking-point-narrow) {
    height: 0;
  }
}

.contract {
  &__content {
    margin: auto;
    width: 90%;
    max-width: 850px;

    &__menu {
      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 20px;
        margin-bottom: 35px;
      }
    }

    &__cards {
      margin-top: -45px;

      &.empty {
        margin-top: 30px;
      }

      &.company {
        margin-top: 20px;
      }

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 0;
      }
    }
  }
}
</style>
