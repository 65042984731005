<template>
  <bb-card size="wide" class="m-y-40">
    <div class="m-y-sm-10">
      <div class="hero-header__no-contracts__opening-header">
        <bb-icon
          name="illustration-opening-header"
          size="fluid"
          fill="mint-70"
        />
      </div>
      <p class="hero-header__no-contracts__welcome-message m-t-20 m-t-sm-35 f-color-navy text-center">
        {{ translations.title }}, {{ username }}!
      </p>
      <p class="hero-header__no-contracts__description m-t-20 m-t-sm-35 smallest f-color-navy align-center">
        {{ translations.description }}
      </p>
    </div>
  </bb-card>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/opening-header'

export default {
  name: 'hero-header__no-contracts',
  props: {
    username: {
      required: true
    },
    translations: {
      required: true,
      type: Object,
      validate: (value) => {
        if (!value.title || !value.description) {
          return new Error('Title and description are required')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.hero-header__no-contracts {
  &__opening-header {
    height: rem(52px);
    width: auto;
  }

  &__welcome-message {
    font-family: $max-pro;
    font-weight: 600;
    font-size: $h4-size;

    &__username {
      text-transform: capitalize;
    }
  }

  &__description {
    font-family: $gotham-medium;
    font-weight: 500;
    font-size: $font-size-smallest;
  }
}
</style>
