<template>
  <bb-modal :visible="visible" @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile>
    <div slot="heading" class="f-color-blue"> {{ translations.modalTitle }}</div>
    <span v-sanitize.basic="translations.description"> </span>
    <div slot="footer" class="modal-footer">
      <div>
        <bb-button :label="translations.no"
                   data-testid="delete-predefined-no-button"
                   inverted
                   color="gray"
                   display="block"
                   @click="$emit('close')"/>
      </div>
      <div>
        <bb-button :label="translations.yes"
                   data-testid="delete-predefined-yes-button"
                   :loading="predefinedPaymentsLoading"
                   :disabled="predefinedPaymentsLoading"
                   color="red"
                   display="block"
                   @click="onDelete"/>
      </div>
    </div>
  </bb-modal>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { usePredefinedPaymentStore } from '@account/store/predefinedPaymentStore'

export default {
  name: 'delete-predefined-payment-modal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    predefinedPaymentId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(usePredefinedPaymentStore, ['predefinedPayments', 'predefinedPaymentsLoading']),
    paymentName () {
      return this.predefinedPayments.find(payment => payment.id === this.predefinedPaymentId)?.name ?? ''
    },
    translations () {
      return {
        modalTitle: this.$pgettext('predefined_payment_delete', 'Delete predefined payment'),
        description: this.$gettextInterpolate(this.$pgettext('predefined_payment_delete',
          'You sure you want to delete predefined payment <strong>"%{paymentName}"</strong>'), {
          paymentName: this.paymentName
        }),
        yes: this.$pgettext('predefined_payment_delete', 'Yes'),
        no: this.$pgettext('predefined_payment_delete', 'No'),
        notificationText: this.$gettextInterpolate(this.$pgettext('predefined_payment_delete',
          'Deleted payment "%{paymentName}"'), {
          paymentName: this.paymentName
        })
      }
    }
  },
  methods: {
    ...mapActions(usePredefinedPaymentStore, ['deletePredefinedPayment']),
    async onDelete () {
      const text = this.translations.notificationText
      await this.deletePredefinedPayment(this.predefinedPaymentId)
      this.$notify({
        type: 'success',
        duration: 5000,
        text
      })

      this.onClose()
    },
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  padding: 10px;

  @media (max-width: $mobile-view-breaking-point) {
    padding: 30px;
    row-gap: 10px;
  }

  div {
    flex: 1 0 0;
  }
}
</style>
