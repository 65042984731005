<template>
  <bb-modal :visible="visible" @close="$emit('close')" flavor="card" full-mobile>
    <template #heading>
      <p class="f-color-blue"> {{ translations.title }} </p>
    </template>
    <bb-form @progress="onProgress" class="predefined-modal">
      <p class="f-small f-color-navy description text-center">{{ translations.modalDescription }}</p>
      <bb-select
        framed
        :label="translations.account"
        name="account"
        v-model="form.accountId"
        :options="accountOptions"
        v-validate="'required'"
        :data-vv-as="translations.account"
        :placeholder="translations.selectAccount"
        data-testid="predefined-payment-account-select"
      />
      <bb-input
        framed
        :label="translations.predefinedPaymentName"
        name="predefinedPaymentName"
        v-validate="`required|max:${nameLength}|unique-name`"
        v-model="form.name"
        :data-vv-as="translations.predefinedPaymentName"
        data-testid="predefined-payment-name"
      />
      <bb-input
        framed
        :label="translations.recipientName"
        name="recipientName"
        v-validate="'required|beneficiary-name|beneficiary-name-length'"
        v-model="form.recipientName"
        :loading="beneficiaryValidation.isLoading"
        :data-vv-as="translations.recipientName"
        data-testid="predefined-payment-recipient-name"
      />
      <bb-input
        framed
        :label="translations.recipientIban"
        :formatter="formatIban"
        name="recipientIban"
        v-validate="'required|iban|beneficiary-iban|unique-beneficiary-iban'"
        v-model.trim="form.recipientIban"
        :loading="beneficiaryValidation.isLoading"
        :data-vv-as="translations.recipientIban"
        data-testid="predefined-payment-recipient-iban"
      />
      <bb-input
        framed
        :label="translations.amount"
        v-validate="'min_value:0.01'"
        :data-vv-as="translations.amount"
        v-model.number="form.amount"
        name="amount"
        :currency="true"
        :locale="locale"
        :add-on="currencySymbol"
        data-vv-validate-on="change|input"
        data-testid="predefined-payment-amount"
        :inputAttrs="{autocomplete: 'off'}"
      />
      <bb-input
        framed
        :label="translations.description"
        :data-vv-as="translations.description"
        name="description"
        ref="description"
        v-model="form.description"
        v-validate="'descriptionSpaceCannotBeFirstChar|descriptionCanContainOnlyFollowingsCharacters|descriptionLength'"
        v-validate.continues="'atLeastOneFilled:referenceNumber'"
        data-testid="predefined-payment-description"
      />
      <bb-input
        framed
        type="text"
        :label="translations.referenceNumber"
        :data-vv-as="translations.referenceNumber"
        :maxlength="maxInputLengths.reference"
        name="referenceNumber"
        ref="referenceNumber"
        v-model.trim="form.referenceNumber"
        v-validate.continues="'atLeastOneFilled:description|referenceCorrectness'"
        data-testid="predefined-payment-reference-number"
      />
      <div slot="submit" slot-scope="{}"/>
    </bb-form>
    <template #footer>
      <bb-button
        data-testid="predefined-payment-save-button"
        :label="translations.save"
        v-bind="button"
        display="block"
        corner="sharpMobileOnly"
        class="m-s-a"
        @click="onSubmit"
      />
    </template>
  </bb-modal>
</template>

<script>
import paymentsMixin from '@account/views/payments/payments.mixin'
import { PREDEFINED_PAYMENT_NAME_MAX_LENGTH } from '@account/views/payments/const'
import {
  PredefinedPaymentType
} from '@bigbank/dc-common/clients/http/account/modules/predefined-payments/predefined-payments.enums'
import { mapActions, mapState } from 'pinia'
import { usePredefinedPaymentStore } from '@account/store/predefinedPaymentStore'

export default {
  name: 'predefined-payment-modal',
  mixins: [paymentsMixin],
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      form: {
        name: ''
      }
    }
  },
  watch: {
    'form.recipientIban' () {
      if (this.form.referenceNumber.length > 0 && this.form.recipientIban.length > 0) {
        this.$validator.fields.length > 0 && this.$validator.validate('referenceNumber')
      }
    },
    // This is needed to trigger bb-form validation
    'form.referenceNumber' (value) {
      this.$validator.fields.length > 0 && this.$validator.validate('description')
    },
    // This is needed to trigger bb-form validation
    'form.description' (value) {
      this.$validator.fields.length > 0 && this.$validator.validate('referenceNumber')
    }
  },
  methods: {
    ...mapActions(usePredefinedPaymentStore, ['getPredefinedPayments', 'createPredefinedPayment', 'modifyPredefinedPayment']),
    async onSubmit () {
      try {
        this.loaders.submit = true
        const {
          accountId,
          ...neededKeys
        } = this.form
        const body = {
          fromIban: this.selectedAccount.iban,
          currencyCode: this.selectedAccount.currencyCode,
          paymentType: PredefinedPaymentType.EUROPEAN,
          ...neededKeys
        }
        if (this.isModifyMode) {
          await this.modifyAndNotify(body)
        } else {
          await this.createAndNofify(body)
        }
      } finally {
        this.loaders.submit = false
        this.$emit('close')
      }
    },
    async createAndNofify (body) {
      await this.createPredefinedPayment(body)
      this.$notify({
        type: 'success',
        duration: 5000,
        text: this.translations.createNotification.text,
        title: this.translations.createNotification.title
      })
    },
    async modifyAndNotify (body) {
      await this.modifyPredefinedPayment(this.predefinedPaymentId, body)
      this.$notify({
        type: 'success',
        duration: 5000,
        text: this.translations.modifyNotification.text,
        title: this.translations.modifyNotification.title
      })
    }
  },
  computed: {
    ...mapState(usePredefinedPaymentStore, ['predefinedPayments']),
    isModifyMode () {
      return this.predefinedPaymentId !== null
    },
    nameLength () {
      return PREDEFINED_PAYMENT_NAME_MAX_LENGTH
    },
    translations () {
      return {
        title: this.$pgettext('predefined_payment', 'European payments'),
        modalDescription: this.$pgettext('predefined_payment', 'Send you money directly to another person or your own account by changing payment type.'),
        heading: this.$pgettext('payment_form', 'Outgoing payment'),
        account: this.$pgettext('payment_form', 'From account'),
        predefinedPaymentName: this.$pgettext('predefined_payment', 'Predefined payment name'),
        recipientName: this.$pgettext('payment_form', 'Recipient name'),
        recipientIban: this.$pgettext('payment_form', 'Recipient IBAN'),
        amount: this.$pgettext('payment_form', 'Amount'),
        description: this.$pgettext('payment_form', 'Description'),
        referenceNumber: this.$pgettext('payment_form', 'Reference number'),
        selectAccount: this.$pgettext('payment_form', 'Select account you wish to transfer from'),
        save: this.$pgettext('predefined_payment', 'Save predefined payment'),
        createNotification: {
          title: this.$pgettext('predefined_payment', 'Predefined payment added'),
          text: this.$pgettext('predefined_payment', 'You\'re all set to use this predefined payment')
        },
        modifyNotification: {
          title: this.$gettextInterpolate(this.$pgettext('predefined_payment_modify',
            'Modified payment "%{paymentName}"'), {
            paymentName: this.form.name
          }),
          text: this.$pgettext('predefined_payment_modify', 'You\'re all set to use this predefined payment')
        },
        errors: {
          uniqueName: this.$pgettext('predefined_payment', 'Predefined payment name is already used')
        }
      }
    }
  },
  mounted () {
    this.getAccounts()
    this.$validator.extend('unique-name', {
      getMessage: () => this.translations.errors.uniqueName,
      validate: (value) => !this.predefinedPayments?.filter(payment => payment.id !== this.predefinedPaymentId)
        .some(payment => payment.name === value)
    })
  }
}
</script>

<style scoped lang="scss">
.predefined-modal {
  max-width: 425px;

  .description {
    line-height: 18px;
    text-align: center;
  }
}
</style>
