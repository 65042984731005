<template>
  <bb-card class="flex-column balance-card" size="fluid">
    <div class="align-center balance-card__label m-b-5">{{ label }}</div>
    <div class="text-center balance-card__amount" :class="`f-color-${isDashVisible ? 'gray' : amountColor}`" >
      <span data-testid="span-main-part">{{ computedAmount.mainPart }}</span>
      <span
        v-if="!isDashVisible"
        class="balance-card__comma-digits"
        data-testid="span-decimal-part">
        {{ decimalSeparator }}{{ computedAmount.decimals }}
      </span>
    </div>
  </bb-card>
</template>
<script>
import { BbCard, BbLabeledValue } from '@bigbank/interface-components'
import { formatMoneyWithCurrency, getDecimalSeparator } from '@/plugins/numformat'
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import { isNil } from 'lodash'

export default {
  name: 'balance-card',
  components: {
    BbCard
  },
  props: {
    label: {
      required: true,
      type: String
    },
    amount: {
      required: true,
      type: Number
    },
    amountColor: {
      type: String,
      default: 'mint'
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'currency']),
    decimalSeparator () {
      return getDecimalSeparator(this.locale)
    },
    isDashVisible () {
      return isNil(this.amount) || this.amount === 0
    },
    computedAmount () {
      const [first, second] = formatMoneyWithCurrency(
        this.amount,
        this.currency,
        this.locale,
        { zeroAs: '-' }
      )
        .split(this.decimalSeparator)

      return {
        mainPart: first,
        decimals: second
      }
    }
  }
}

</script>
<style lang="scss">
.balance-card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  max-width: 100% !important;
  min-height: 90px;

  @media (min-width: $desktop-view-breaking-point) {
    height: 190px;
  }

  &__label {
    color: $navy;
    font-family: $gotham-medium;
    line-height: 16px;
    font-size: $font-size-tiny;
    text-transform: uppercase;
  }

  &__amount {
    font-family: $gotham-medium;
    line-height: 28px;
    font-size: $h2-size;
    letter-spacing: -0.05em;
  }

  &__comma-digits {
    font-size: $h3-size;
    display: inline-block;

    @media (max-width: $mobile-view-breaking-point) {
      font-size: $h4-size;
    }
  }
}
</style>
