<template>
  <bb-modal :visible="visible" flavor="card" @close="closeModal">
    <template #heading>
      <p class="f-color-blue">{{ translations.editLimits }}</p>
    </template>
    <bb-form
      static-submit
      :error-text="translations.saveChanges"
      :submit-text="translations.saveChanges"
      :disabled="isOver15kLimit"
      @submit="onSubmit"
      data-testid="edit-limits-form"
    >
      <p class="f-small text-center f-color-gray m-b-15">{{ accountNumber }}</p>
      <bb-box thin class="limit-box bg-gray-10 m-b-10">
        <div>
          <bb-input
            v-model="dailyLimit"
            :label="translations.dailyLimit"
            name="daily-limit"
            small
            currency
            :add-on="currencySymbol"
            class="m-b-10"
            :rules="dailyValidationRules"
            :messages="dailyValidationMessages"
            data-vv-validate-on="change"
          />
          <limit-box-progress
            v-bind="dailyLimitProgressProps"
          />
        </div>
      </bb-box>
      <bb-box thin class="limit-box bg-gray-10">
        <div>
          <bb-input
            v-model="monthlyLimit"
            :label="translations.monthlyLimit"
            name="monthly-limit"
            small
            currency
            :add-on="currencySymbol"
            class="m-b-10"
            :rules="monthlyValidationRules"
            :messages="monthlyValidationMessages"
            data-vv-validate-on="change"
          />
          <limit-box-progress
            v-bind="monthlyLimitProgressProps"
          />
        </div>
      </bb-box>
      <bb-box
        v-show="!verificationData.isF2fIdentified"
        thin
        class="bg-gray-10 color-gray f-smallest m-y-15 not-identified"
      >
        <div class="d-flex m-b-5">
          <bb-icon name="ui-alert-outline" fill="yellow" size="24" />
          <p class="m-l-5 not-identified__title">{{ translations.notIdentified }}</p>
        </div>
        <span class="f-gotham-book"> {{ translations.notIdentifiedText }}</span>
        <bb-link :to="{ name: AccountRouteName.AccountVerification }" class="color-mint">
          {{ translations.notIdentifiedClickHere }}
        </bb-link>
      </bb-box>
    </bb-form>
  </bb-modal>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import LimitBoxProgress from './LimitBoxProgress.vue'
import { currencyCodeToSymbol } from '@/plugins/currencyFormatters'
import { useAccountStore } from '@account/store/accountStore'
import { AccountRouteName } from '@account/const'

export default {
  name: 'edit-limit-modal',
  data () {
    return {
      loading: false,
      dailyLimit: 0,
      monthlyLimit: 0,
      AccountRouteName
    }
  },
  components: {
    LimitBoxProgress
  },
  props: {
    visible: {
      type: Boolean
    },
    accountNumber: {
      type: String,
      required: true
    },
    dailyLimitProgressProps: {
      type: Object,
      validator: (val) => {
        return true
      }
    },
    monthlyLimitProgressProps: {
      type: Object,
      validator: (val) => {
        return true
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.dailyLimit = this.dailyLimitProgressProps.limitRaw
        this.monthlyLimit = this.monthlyLimitProgressProps.limitRaw
      }
    },
    monthlyLimit (val) {
      this.monthlyLimit = val
    }
  },
  computed: {
    ...mapState(useRootStore, ['currency', 'locale']),
    ...mapState(useAccountStore, ['verificationData']),
    currencySymbol () {
      return currencyCodeToSymbol(this.currency)
    },
    isOver15kLimit () {
      if (this.verificationData.isF2fIdentified) {
        return false
      }
      return this.dailyLimit > 15000 || this.monthlyLimit > 15000
    },
    dailyValidationRules () {
      return {
        required: Boolean,
        max_value: v => v <= this.monthlyLimit,
        max_length: v => String(v).length <= 7,
        no_symbols: v => !/[^0-9]/.test(v)
      }
    },
    dailyValidationMessages () {
      return {
        error: {
          required: this.translations.errors.dailyLimitRequired,
          max_value: this.translations.errors.dailyLimitShouldNotExceedMonthly,
          max_length: this.translations.errors.dailyLimitTooLong,
          no_symbols: this.translations.errors.dailyLimitNoSymbols
        }
      }
    },
    monthlyValidationRules () {
      return {
        required: Boolean,
        min_value: v => v >= this.dailyLimit,
        max_length: v => String(v).length <= 7,
        no_symbols: v => !/[^0-9]/.test(v)
      }
    },
    monthlyValidationMessages () {
      return {
        error: {
          required: this.translations.errors.monthlyLimitRequired,
          min_value: this.translations.errors.dailyLimitShouldNotExceedMonthly,
          max_length: this.translations.errors.monthlyLimitTooLong,
          no_symbols: this.translations.errors.monthlyLimitNoSymbols
        }
      }
    },
    translations () {
      return {
        dailyLimit: this.$pgettext('EDB_limits', 'Daily limit'),
        monthlyLimit: this.$pgettext('EDB_limits', 'Monthly limit'),
        editLimits: this.$pgettext('EDB_limits', 'Edit limits'),
        saveChanges: this.$pgettext('EDB_limits', 'Save changes'),
        notIdentified: this.$pgettext('EDB_limits', 'Customer is not identified'),
        notIdentifiedText: this.$pgettext('EDB_limits', 'Everyone who has not done Face to Face identification, has a monthly spending limit of €15,000. This includes internet bank transfers. To learn more'),
        notIdentifiedClickHere: this.$pgettext('EDB_limits', 'click here'),
        errors: {
          dailyLimitRequired: this.$pgettext('EDB_limits', 'Daily limit field is required'),
          dailyLimitShouldNotExceedMonthly: this.$pgettext('EDB_limits', 'Daily limit should not exceed monthly limit'),
          monthlyLimitRequired: this.$pgettext('EDB_limits', 'Monthly limit field is required'),
          dailyLimitTooLong: this.$pgettext('EDB_limits', 'Daily limit is too long'),
          monthlyLimitTooLong: this.$pgettext('EDB_limits', 'Monthly limit is too long'),
          dailyLimitNoSymbols: this.$pgettext('EDB_limits', 'Daily limit should be round number'),
          monthlyLimitNoSymbols: this.$pgettext('EDB_limits', 'Monthly limit should be round number')
        }
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    onSubmit () {
      alert('submit')
    }
  }
}
</script>
<style lang="scss" scoped>
.not-identified__title {
  line-height: 24px;
}
</style>
