import { defineStore } from 'pinia'
import api from '@/modules/account/api'

export const usePredefinedPaymentStore = defineStore('predefinedPayment', {
  state: () => ({
    predefinedPayments: [],
    predefinedPaymentsLoading: true
  }),
  actions: {
    async getPredefinedPayments () {
      this.predefinedPaymentsLoading = true
      try {
        this.predefinedPayments = await api.getPredefinedPayments()
      } finally {
        this.predefinedPaymentsLoading = false
      }
    },
    async getPredefinedById (predefinedPaymentId) {
      const payment = this.predefinedPayments.find(payment => payment.id === predefinedPaymentId)
      if (payment?.id) return payment

      try {
        return await api.getPredefinedPayment(predefinedPaymentId)
      } catch (e) {
        // ignore
      }
    },
    async createPredefinedPayment (body) {
      try {
        await api.createPredefinedPayment(body)
        await this.getPredefinedPayments()
      } catch (err) {
        this.$apm.captureError(err)
      }
    },
    async modifyPredefinedPayment (predefinedPaymentId, body) {
      try {
        await api.modifyPredefinedPayment(predefinedPaymentId, body)
        await this.getPredefinedPayments()
      } catch (err) {
        this.$apm.captureError(err)
      }
    },
    async deletePredefinedPayment (predefinedPaymentId) {
      try {
        await api.deletePredefinedPayment(predefinedPaymentId)
      } catch (e) {
        // ignore
      }
      await this.getPredefinedPayments()
    }
  }
})
