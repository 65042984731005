<template>
  <bb-container v-if="hasLoansAndLeasings" fluid class="corporate-hero-header p-y-20 p-x-0">
    <bb-row gutter="10" >
    <bb-col v-if="hasLeasings" class="col-24" :class="hasLoans ? 'col-md-12' : 'col-md-24'">
      <balance-card
        data-testid="corporate-balance-card-total-leasings"
        :label="translations.remainingPrincipalsLeasing"
        :amount="leasingsTotal"
        :amountColor="isLeasingInDebt ? 'red' : 'mint'"
      />
    </bb-col>
    <bb-col v-if="hasLoans" class="col-24 p-10 p-md-0" :class="hasLeasings ? 'col-md-12' : 'col-md-24'">
      <balance-card
        data-testid="corporate-balance-card-total-loans"
        :label="translations.remainingPrincipalsLoans"
        :amount="loansTotal"
        :amountColor="isLoanInDebt ? 'red' : 'mint'"
      />
    </bb-col>
  </bb-row>
  </bb-container>
  <div v-else>
    <no-contracts-company
      :username="company.representativeName"
      :translations="translations"
    />
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import NoContractsCompany from '@/components/hero-header/NoContractsCompany.vue'
import BalanceCard from '@/components/cards/BalanceCard.vue'

export default {
  name: 'company-hero-header',
  components: {
    NoContractsCompany,
    BalanceCard
  },
  data () {
    return {
      value: 100.20,
      label: 'Remaining principal for loans'
    }
  },
  computed: {
    ...mapState(useRootStore, ['company', 'locale']),
    ...mapState(useLoanStore, {
      loansAndLeasings: 'loans'
    }),
    translations () {
      return {
        title: this.$pgettext('company_hero_header', 'Welcome to Self-Service'),
        description: this.$pgettext('company_hero_header', 'A single destination for your loan application statuses and latest transactions.'),
        remainingPrincipalsLeasing: this.$pgettext('company_hero_header', 'Remaining principal for leasings'),
        remainingPrincipalsLoans: this.$pgettext('company_hero_header', 'Remaining principal for loans')
      }
    },
    hasLoansAndLeasings () {
      return this.loansAndLeasings.length > 0
    },
    loans () {
      return this.loansAndLeasings.filter(({ typeCode }) => [
        InternalLoanType.LOAN,
        InternalLoanType.HOUSING,
        InternalLoanType.REAL_ESTATE
      ].includes(typeCode))
    },
    leasings () {
      return this.loansAndLeasings.filter(({ typeCode }) => [
        InternalLoanType.LEASING
      ].includes(typeCode))
    },
    hasLoans () {
      return this.loans.length > 0
    },
    hasLeasings () {
      return this.leasings.length > 0
    },
    loansTotal () {
      return this.loans.reduce((acc, loan) => acc + loan.remainingPrincipal, 0)
    },
    leasingsTotal () {
      return this.leasings.reduce((acc, leasing) => acc + leasing.remainingPrincipal, 0)
    },
    isLoanInDebt () {
      return this.loans.find(({ isInDebt }) => isInDebt)
    },
    isLeasingInDebt () {
      return this.leasings.find(({ isInDebt }) => isInDebt)
    }
  }
}
</script>
<style lang="scss">
.corporate-hero-header {
  margin: auto;
  width: 90%;
  max-width: 850px;
}
</style>
