import { defineStore } from 'pinia'
import { useRootStore } from '@/store/root'
import api from '../api'
import { mapContract } from './util'
import store from '@/store'
import CARD_TYPE from '@/components/cards/cardTypes'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { useCreditCardStore } from '@/store/modules/credit-card'
import { sortByActiveStatus } from '../helpers/sort-contracts'

const loader = { count: 0 }

export const useLoanStore = defineStore('loan', {
  state: () => ({
    contractId: null,
    contracts: [],
    isContractsLoading: false,
    applications: [],
    loading: false,
    featureFlags: {},
    upsell: null,
    promiseToPay: null,
    contractAdditionalDetails: null,
    dashboardContracts: []
  }),
  getters: {
    contract () {
      return this.contracts.find(({ id }) => id === this.contractId)
    },
    revolvingLoan () {
      return this.contracts
        .filter(({ typeCode }) => typeCode === InternalLoanType.REVOLVING)
        .sort(sortByActiveStatus)[0]
    },
    loans () {
      return this.contracts.filter(({ typeCode }) => [
        InternalLoanType.LOAN,
        InternalLoanType.HOUSING,
        InternalLoanType.REAL_ESTATE,
        InternalLoanType.LEASING
      ].includes(typeCode))
    },
    isPromiseToPayShown (state) {
      const rootStore = useRootStore()
      return (contracts = null) => {
        return state.promiseToPay &&
          !this.hasTerminatedContracts((contracts ?? this.loans)) &&
          rootStore.featureFlags.enablePromiseToPay
      }
    },
    hasTerminatedContracts () {
      return (contracts = null) => {
        return [contracts ?? this.loans].reduce((acc, contract) => (acc || contract.isTerminated), false)
      }
    },
    isInDebt () {
      return (contracts = null) => {
        return (contracts ?? this.loans).reduce((acc, contract) => (acc || ((contract.isInDebt || contract.isTerminated) && !contract.isClosed)), false)
      }
    },
    isClosed () {
      return (contracts = null) => {
        return (contracts ?? this.loans).reduce((acc, contract) => (acc && contract.isClosed), true)
      }
    },
    getApplicationsByType (state) {
      return (applicationType) => {
        return (Array.isArray(state.applications) ? state.applications : [])
          .filter(({ typeCode }) => typeCode === applicationType)
          .reduce((acc, application) => {
            const index = application.status.toLowerCase() === 'closed' ? 'closed' : 'active'
            application.type = CARD_TYPE.APPLICATION
            acc[index].push(application)
            return acc
          }, { active: [], closed: [] })
      }
    }
  },
  actions: {
    async initModule () {
      this.setLoading(true)
      await this.getFeatureFlags()
      this.setLoading(false)
    },
    setLoading (isLoading) {
      if (isLoading) {
        loader.count++
      } else {
        loader.count--
      }

      this.loading = loader.count > 0
    },
    async init () {
      const rootStore = useRootStore()
      const creditCardStore = useCreditCardStore()

      if (!rootStore.isCustomer) {
        return
      }

      this.setLoading(true)
      creditCardStore.init()

      await Promise.all([
        this.getContracts(),
        this.getApplications(),
        this.getUpsellData(),
        this.getPromiseToPay(),
        this.getFeatureFlags()
      ])

      await store.dispatch('campaign/getCampaigns', null, { root: true })
      this.setLoading(false)
    },
    async initForMainDashboard () {
      const rootStore = useRootStore()
      const creditCardStore = useCreditCardStore()
      const isLoansSummaryEnabled = rootStore.featureFlags.enableLoanDashboardSummary && !rootStore.isCompany

      if (!rootStore.isCustomer) {
        return
      }

      this.setLoading(true)
      creditCardStore.init()

      await Promise.all([
        this.getFeatureFlags(),
        (isLoansSummaryEnabled ? this.getLoansSummary() : this.getContracts())
      ])
      this.setLoading(false)
    },
    async getContracts (opts = {}) {
      if (opts.showLoader) {
        this.setLoading(true)
      }

      try {
        this.isContractsLoading = true
        let contracts = await api.getContracts()
        if (!Array.isArray(contracts)) {
          contracts = contracts ? [contracts] : []
        }
        this.contracts = contracts.map(mapContract)

        if (opts.showLoader) {
          this.setLoading(false)
        }
      } finally {
        this.isContractsLoading = false
      }
    },
    async getLoansSummary () {
      try {
        this.isContractsLoading = true
        let contracts = await api.getContractsSummary({
          statusList: ['ACTIVE'],
          pageSize: 100,
          pageNumber: 1
        })

        if (!Array.isArray(contracts)) {
          contracts = contracts ? [contracts] : []
        }

        this.dashboardContracts = contracts
          .filter(({ typeCode }) => [
            InternalLoanType.LOAN,
            InternalLoanType.HOUSING,
            InternalLoanType.REAL_ESTATE,
            InternalLoanType.LEASING
          ].includes(typeCode))
      } finally {
        this.isContractsLoading = false
      }
    },

    async getApplications () {
      const rootStore = useRootStore()
      const reason = 'get_applications'
      let applications = []

      try {
        applications = await api.getApplications()
        rootStore.reportSuccessfulRequest({ reason })
      } catch (err) {
        rootStore.showPartialFailureNotification({ reason })
      }
      this.applications = applications
    },
    async getUpsellData () {
      try {
        const upsell = await api.getUpsell()
        if (upsell) {
          this.upsell = {
            amount: upsell.upsellAmount,
            segment: upsell.segmentCode,
            currency: upsell.currencyCode,
            url: upsell.upsellStartUrl
          }
        }
      } catch (e) {
        // Error is ignored, as displaying uppsell is not business critical error
      }
    },
    async getFeatureFlags () {
      const rootStore = useRootStore()

      if (Object.keys(this.featureFlags).length) {
        return this.featureFlags
      }

      const featureFlags = await api.getFeatureFlags()
      this.featureFlags = Object.assign({}, rootStore.featureFlags, featureFlags)

      return featureFlags
    },
    async getContract (contractId) {
      this.contractId = (contractId || '').toString()
      if (this.contracts.filter(({ id }) => id === contractId).length) {
        return
      }
      await this.getContracts()
    },
    async getPromiseToPay () {
      this.promiseToPay = await api.getPromiseToPay()
    }
  }
})
