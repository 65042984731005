<template>
  <layout :loading="loading">
    <bb-container class="tax-reports" fluid v-if="!loading">
      <bb-row class="p-b-60">
        <bb-col md="12" lg="11">
          <section-box :title="translations.interestCertificate.title" icon="client-upload">
            {{ translations.interestCertificate.subTitle }}
          </section-box>
        </bb-col>
        <bb-col md="12" lg="13" class="tax-reports__container">
          <bb-list-group>
            <bb-list-item
              condensed
              class="tax-reports__report-item"
              @click.native="generate(report, PrintoutTypeCode.DepoInterestCertificate)"
              v-for="(report, index) in interestCertificates"
              :noBottomBorder="!index"
              :key="report.year"
              :titleUppercase="false"
              :title="report.title">
              <bb-icon class="m-r-10" v-if="!report.generating" size="24" fill="blue" name="ui-download" />
              <bb-spinner v-else small />
            </bb-list-item>
          </bb-list-group>
        </bb-col>
      </bb-row>
      <bb-row  v-if="featureFlags.enableDepositBalanceCertificateDownload">
        <bb-col md="12" lg="11">
          <section-box :title="translations.balanceCertificate.title" icon="client-pdf">
            {{ translations.balanceCertificate.subTitle }}
          </section-box>
        </bb-col>
        <bb-col md="12" lg="13" class="tax-reports__container">
          <bb-list-group>
            <bb-list-item
              condensed
              class="tax-reports__report-item"
              @click.native="generate(report, PrintoutTypeCode.DepoBalanceCertificate)"
              v-for="(report, index) in balanceCertificates"
              :noBottomBorder="!index"
              :key="report.year"
              :titleUppercase="false"
              :title="report.title">
              <bb-icon class="m-r-10" v-if="!report.generating" size="24" fill="blue" name="ui-download" />
              <bb-spinner v-else small />
            </bb-list-item>
          </bb-list-group>
        </bb-col>
      </bb-row>
    </bb-container>
  </layout>
</template>

<script>
import Layout from './Layout'
import SectionBox from '@account/components/SectionBox'
import api from '@account/api'
import { SETTINGS_VIEW } from '@/TrackingActions'
import { mapState, mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import '@bigbank/interface-components/dist/svg/client/pdf'
import '@bigbank/interface-components/dist/svg/ui/download'
import '@bigbank/interface-components/dist/svg/client/upload'
import { PrintoutTypeCode } from '@bigbank/dc-common/clients/http/account/account.enums'
import { useRootStore } from '@/store/root'

export default {
  name: 'account-documents',
  components: { Layout, SectionBox },
  data () {
    return {
      PrintoutTypeCode,
      loading: true,
      activeReport: null,
      printoutList: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags']),
    ...mapState(useAccountStore, ['details']),
    translations () {
      return {
        downloadLabel: this.$pgettext('tax_report_download_link', '% - Download your report'),
        interestCertificate: {
          title: this.$pgettext('interest_certificate', 'Tax Documents'),
          subTitle: this.$pgettext('interest_certificate', 'Here you will find the lists of your capital gains')
        },
        balanceCertificate: {
          title: this.$pgettext('annual_reports', 'Annual Reports'),
          subTitle: this.$pgettext('annual_reports', 'Here you can view and download the annual overviews of your deposit contracts')
        }
      }
    },
    yearlyListOfReports () {
      return this.printoutList?.availableDocumentsList.map(item => {
        item.title = this.translations.downloadLabel.replace('%', item.year)
        item.generating = this.activeReport &&
          this.activeReport.year === item.year &&
          this.activeReport.typeCode === item.typeCode
        return item
      })
    },
    interestCertificates () {
      return this.yearlyListOfReports.filter(({ typeCode }) => PrintoutTypeCode.DepoInterestCertificate.includes(typeCode))
    },
    balanceCertificates () {
      return this.yearlyListOfReports.filter(({ typeCode }) => PrintoutTypeCode.DepoBalanceCertificate.includes(typeCode))
    }
  },
  methods: {
    ...mapActions(useAccountStore, ['getDetails']),
    async generate (item, typeCode) {
      if (this.activeReport) {
        return
      }

      this.activeReport = item
      const downloadResponse = await api.generateDepositTaxReport(item.year, typeCode)
      this.$tracker.action(SETTINGS_VIEW.DOWNLOAD_TAX_REPORT)
      api.downloadTaxReport(downloadResponse)
      this.activeReport = null
    }
  },
  async mounted () {
    this.loading = true
    this.printoutList = await api.getPrintoutList()
    this.loading = false
  }
}
</script>

<style lang="scss">
.tax-reports {
  &__container {
    margin-top: 25px;

    @media (min-width: $desktop-view-breaking-point) {
      margin: 0;
    }
  }

  &__report-item {
    cursor: pointer;

    .bb-spinner {
      height: 21px;
      width: 21px;
      margin-right: 10px;
    }
  }
}
</style>
