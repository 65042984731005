<template>
  <div>
    <p class="f-smallest f-color-gray">{{ title }}</p>
    <bb-progress-bar
      :color="isLimitExceeded ? 'gray-70' : 'green'"
      corner="round"
      :value="limitSpent"
      :max="limitRaw"
      class="m-y-5"
      :data-testid="`${type}-progress-bar`"
    />
    <bb-row class="f-smallest f-color-gray f-gotham-book">
      <bb-col>
        <p>{{ limitSpentText }}</p>
      </bb-col>
      <bb-col class="f-align-right">
        <p>{{ limitLeftText }}</p>
      </bb-col>
    </bb-row>
  </div>
</template>
<script>
import { BbProgressBar } from '@bigbank/interface-components'

export default {
  name: 'limit-box',
  components: {
    BbProgressBar
  },
  props: {
    type: {
      type: String,
      validator: (val) => ['daily', 'monthly'].includes(val)
    },
    title: {
      type: String,
      require: true
    },
    isLimitExceeded: {
      type: Boolean
    },
    limitSpent: {
      type: Number,
      require: true
    },
    limitRaw: {
      type: Number,
      require: true
    },
    limitSpentText: {
      type: String,
      require: true
    },
    limitLeftText: {
      type: String,
      require: true
    }
  }
}
</script>
