<template>
  <div class="step-two">
    <div class="step-two__amount">
      <p class="step-two__amount-title color-gray-90 uppercase">
        {{ translations.depositAmount }}
      </p>
      <p class="step-two__amount-sum color-blue">
        {{ formattedDepositSum }}
      </p>
    </div>
    <div
      v-if="specialInterestRequest"
      class="step-two__special-interest-text"
      v-sanitize.basic="specialInterestTranslations"
    />
    <payment-frequency
        v-model="paymentFrequencyCode"
        :limits="flowData.limits"
    ></payment-frequency>
    <div class="step-two__radio-card-wrapper">
      <div v-if="showDefaultOffer" class="current-plan-choice-title">
        <p>
          <translate>My current plan</translate>:
        </p>
      </div>
      <div class="radio-card">
        <offer-radio-card
          v-if="showDefaultOffer"
          @select="selectOffer"
          :amount="amount"
          :offers="defaultOffers"
          :paymentFrequencyCode="paymentFrequencyCode"
          :accountType="flowData.accountType"
          :manual-prolong="manualProlong"
          :start-date="startDate"
          :agreement-type-code="agreementTypeCode"
          :locale="locale"
        />
      </div>
      <div class="prolong-choice-title">
        <p>
          {{prolongChoiceTitle}}:
        </p>
      </div>
      <div class="radio-card">
        <offer-radio-card
          @select="selectOffer"
          :amount="amount"
          :offers="filterOffers"
          :start-date="startDate"
          :paymentFrequencyCode="paymentFrequencyCode"
          :accountType="flowData.accountType"
          :manual-prolong="manualProlong"
          :agreement-type-code="agreementTypeCode"
          :locale="locale"
        />
      </div>
    </div>
    <div class="step-two__another-sum-wrapper">
      <bb-button color="blue" display="link" @click="goToDifferentPlan" class="another-sum" noUnderline>
        <bb-icon fill="gray-40" name="ui-pencil-circle-outline" size="24" />
        {{anotherSumText}}
      </bb-button>
    </div>
    <div class="continue-button-container">
      <continue-button
              :text="continueText"
              :handleClick="submit"
              :disabled="!isFormValid || !allowToContinue"
      ></continue-button>
    </div>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/pencil-circle-outline'
import OfferRadioCard from './OfferRadioCard'
import PaymentFrequency from './PaymentFrequency'
import ContinueButton from './ContinueButton'
import { api } from '../../api'
import getCurrency from '../../utils/getCurrency'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'

export default {
  name: 'prolongation-step-two',
  components: {
    OfferRadioCard,
    PaymentFrequency,
    ContinueButton
  },

  data () {
    return {
      offers: [],
      selectedOfferRank: '',
      nextStep: '',
      startDate: this.flowData.startDate,
      period: this.flowData.period,
      amount: this.flowData.amount,
      agreementTypeCode: this.flowData.agreementTypeCode,
      translations: {
        depositAmount: this.$gettext('Deposit amount'),
        anotherSum: this.$gettext('I want another sum'),
        lowerSum: this.$gettext('I want lower sum')
      },
      paymentFrequencyCode: this.flowData.paymentFrequencyCode,
      periodCode: this.flowData.periodCode,
      defaultOffer: {
        period: this.flowData.deposit.period,
        periodCode: this.flowData.periodCode,
        rank: 'currentPlan',
        selected: false,
        annualInterestRate: null,
        bonusInterest: null
      }
    }
  },
  props: {
    flowData: Object
  },
  created () {
    this.selectedOfferRank = 'currentPlan'
  },
  mounted () {
    this.getOffers()
  },
  methods: {
    selectOffer (offer) {
      this.defaultOffer.selected = false
      this.selectedOfferRank = 'currentPlan'
      this.offers.forEach(o => {
        o.selected = false
      })
      if (offer) {
        offer.selected = true
        this.period = offer.period
        this.bonusInterest = offer.bonusInterest
        this.selectedOfferRank = offer.rank
        this.periodCode = offer.periodCode
      }
    },
    getOffers () {
      api.getDepositOffer(this.depositId)
        .then(offers => this.sortOffersByRank(offers))
        .then(offers => this.getValidPaymentFrequenciesForOffers(offers))
        .then(offers => {
          this.offers = offers.map(offer => {
            offer.selected = false
            offer.annualInterestRate = null
            offer.bonusInterest = null
            return offer
          })
        })
    },
    goToDifferentPlan () {
      this.$emit('goToDifferentPlan', {
        period: this.period,
        paymentFrequencyCode: this.paymentFrequencyCodeForSubmit
      })
    },
    async submit () {
      this.$emit('submit', {
        period: this.period,
        periodCode: this.periodCode,
        paymentFrequencyCode: this.paymentFrequencyCodeForSubmit,
        annualInterestRate: this.selectedOffer.annualInterestRate,
        bonusInterest: this.selectedOffer.bonusInterest
      })
    },
    sortOffersByRank (offers) {
      offers.sort((a, b) => a.rank - b.rank)

      return offers
    },
    getValidPaymentFrequenciesForOffers (offers) {
      const enrichedOffers = []
      for (const offer of offers) {
        offer.paymentFrequencyCodes = []
        for (const depositParameter of this.flowData.parameters) {
          if (offer.periodCode === depositParameter.periodCode &&
            offer.period >= depositParameter.minPeriod &&
            offer.period <= depositParameter.maxPeriod) {
            offer.paymentFrequencyCodes.push(depositParameter.interestPaymentFrequencyCode)
          }
        }

        enrichedOffers.push(offer)
      }

      return enrichedOffers
    }
  },
  watch: {
    allOffers: {
      handler (offers) {
        const selected = offers.find(offer => offer.selected)
        if (!selected && this.defaultOffer) {
          this.selectOffer(this.defaultOffer)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['isChannelNL', 'isChannelBG']),
    paymentFrequencyCodeForSubmit () {
      let paymentFrequencyCode

      if (this.isChannelNL || this.isChannelBG) {
        paymentFrequencyCode = this.paymentFrequencyCode
      } else {
        paymentFrequencyCode = this.period < 12 ? 'END' : this.paymentFrequencyCode
      }
      return paymentFrequencyCode
    },
    defaultOffers () {
      return [this.defaultOffer]
    },
    allOffers () {
      return this.defaultOffers.concat(this.filterOffers)
    },
    selectedOffer () {
      return this.allOffers.find(offer => offer.selected)
    },
    formattedDepositSum () {
      return formatMoneyWithCurrency(this.amount, this.currency, this.locale)
    },
    depositId () {
      return this.flowData.deposit.id
    },
    currency () {
      return getCurrency(this.flowData.deposit.currencyCode)
    },
    continueText () {
      return this.$gettext('Continue')
    },
    filterOffers () {
      return this.offers.filter(offer => offer.paymentFrequencyCodes.includes(this.paymentFrequencyCode))
    },
    showDefaultOffer () {
      const isValidPeriodCode = this.flowData.periodCode === this.flowData.deposit.periodCode
      const isValidPeriod = this.limits.validPeriods.includes(this.flowData.deposit.period)

      return isValidPeriodCode && isValidPeriod
    },
    prolongChoiceTitle () {
      return this.showDefaultOffer ? this.$gettext('Or to prolong') : this.$gettext('Choose plan')
    },
    isFormValid () {
      if (this.showDefaultOffer) {
        return true
      } else {
        return this.selectedOfferRank !== null && this.selectedOfferRank !== 'currentPlan'
      }
    },
    manualProlong () {
      return this.flowData.activeView === this.flowData.views.prolongationFlow
    },
    limits () {
      return this.flowData.limits[this.paymentFrequencyCode]
    },
    specialInterestRequest () {
      return (this.flowData.specialInterestRequest)
    },
    specialInterestAmount () {
      return formatMoneyWithCurrency(this.limits.maxAmount, this.currency, this.locale)
    },
    anotherSumText () {
      return (this.specialInterestRequest) ? this.translations.lowerSum : this.translations.anotherSum
    },
    specialInterestTranslations () {
      return this.$gettextInterpolate(
        this.$gettext(
          'As your deposit sum exceeds <strong>%{amount}</strong>, after receiving your application,\n' +
          'we shall contact you to finalise the interest rate'
        ), { amount: this.specialInterestAmount }
      )
    },
    locale () {
      return this.flowData.locale
    },
    allowToContinue () {
      return this.filterOffers
        .reduce((hasInterest, offer) => hasInterest && !!offer.annualInterestRate, true) && this.selectedOffer?.annualInterestRate
    }
  }
}
</script>

<style lang="scss" scoped>
  .step-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 41px;

    @media (min-width: $desktop-view-breaking-point) {
      max-width: 380px;
      margin: 0 auto;
      padding-top: 40px;
    }

    &__radio-card-wrapper {
      font-size: $mobile-small-font-size;
      font-family: $gotham-medium;

      p {
        text-align: left;
        color: $gray-90;
      }

      .current-plan-choice-title {
        margin-top: 20px;
      }

      .prolong-choice-title {
        margin-top: 11px;
      }

      .radio-card {
        margin-top: 16px;
      }
    }

    &__amount {
      &-title {
        font-family: $gotham-medium;
        font-size: $font-size-smallest;
      }

      &-sum {
        font-family: $gotham-bold;
        font-size: $mobile-h2-size;

        span {
          font-size: $mobile-h3-size;
        }
      }
    }

    &__another-sum-wrapper {
      margin-top: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 35px;
      max-height: 35px;

      @media (min-width: $desktop-view-breaking-point + 1) {
        margin-top: 76px;
      }

      svg {
        margin-right: 13px;
      }

      .another-sum {
        font-family: $gotham-book;
        font-size: $mobile-default-font-size;
      }
    }

    &__special-interest-text {
      font-size: $font-size-tiny;
      font-family: $gotham-book;
      color: $gray-80;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .continue-button-container {
    margin-top: 55px;
    width: 100%;
  }
</style>
