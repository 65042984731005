<template>
  <div class="offer-page__main-container">
    <template v-if="hasOffers">
      <div class="offer-page__offer-container">
        <h4>{{ translations.title }}</h4>
        <div class="payment-frequency__container">
          <payment-frequency
            v-model="paymentFrequencyCode"
            :limits="flowData.limits"
            :account-defaults="flowData.accountDefaults"
          />
        </div>
        <bb-slider
          class="offer-page__slider"
          :label="translations.howMuch"
          :unit="currency"
          :min="minAmount"
          :max="maxAmount"
          :step="amountStep"
          :locale="locale"
          v-model="amount"
          @input="onAmountChanged"
        />
        <div class="offer-page__which-offer">{{ translations.whichOffer}}</div>
        <offer-radio-card
          class="m-t-15"
          :offers="offers"
          @select="selectOffer"
          @loading="handleLoading"
          :amount="amount"
          :paymentFrequencyCode="paymentFrequencyCode"
          :accountType="flowData.accountType"
          :start-date="startDate"
          :agreement-type-code="agreementTypeCode"
          :locale="locale"
        />
      </div>
      <div class="offer-page__different-plan">
        <bb-button color="blue" display="link" @click="goToDifferentPlan" class="offer-page__different-plan-link" noUnderline>
          <bb-icon fill="gray-40" name="ui-pencil-circle-outline" size="24" />
          {{ translations.differentPlan }}
        </bb-button>
      </div>
      <continue-button
        :text="translations.continue"
        :handleClick="submitOfferPage"
        :disabled="!allowToContinue"
      />
    </template>
    <bb-banner v-else type="error" visible permanent class="m-t-30">
      {{ translations.noOffers }}
    </bb-banner>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/ui/pencil-circle-outline'
import PaymentFrequency from './PaymentFrequency'
import OfferRadioCard from './OfferRadioCard'
import ContinueButton from './ContinueButton'
import getCurrency from '../../utils/getCurrency'
import OffersMixin from '../../mixins/offersMixin'
import SliderMixin from '../../mixins/sliderMixin'
import { useRootStore } from '../../../../store/root'
import { mapState } from 'pinia'

export default {
  name: 'OfferPage',
  mixins: [
    OffersMixin,
    SliderMixin
  ],
  props: {
    flowData: Object
  },
  mounted () {
    this.offers = this.filterAndSortOffers(this.flowData.accountDefaults, this.paymentFrequencyCode)
    this.resetSelectedOffer(this.amount)
    this.matchOfferWithPeriod()
  },
  data () {
    return {
      startDate: this.flowData.startDate,
      amount: this.flowData.amount || 0,
      period: this.flowData.period || 24,
      offers: [],
      allowToContinue: false,
      paymentFrequencyCode: this.getInitialPaymentFrequencyCode(),
      selectedOffer: this.flowData.selectedOffer || null,
      offerPageData: {},
      agreementTypeCode: this.flowData.agreementTypeCode
    }
  },
  components: {
    OfferRadioCard,
    PaymentFrequency,
    ContinueButton
  },
  methods: {
    selectOffer (offer) {
      (this.offers || []).forEach(offer => {
        offer.selected = false
      })
      if (!offer) {
        this.selectedOffer = null
      } else {
        offer.selected = true
        this.selectedOffer = offer
        this.period = offer.period
      }
    },
    handleLoading (result) {
      if (!result) {
        this.calculateContinue()
      }
    },
    async submitOfferPage () {
      this.$emit('submit', {
        amount: this.amount,
        period: this.period,
        paymentFrequencyCode: this.paymentFrequencyCode,
        selectedOffer: this.selectedOffer,
        annualInterestRate: this.selectedOffer.annualInterestRate,
        bonusInterest: this.selectedOffer.bonusInterest
      })
    },
    goToDifferentPlan () {
      this.$emit('goToDifferentPlan', {
        amount: this.amount,
        period: this.period,
        paymentFrequencyCode: this.paymentFrequencyCode,
        selectedOffer: this.selectedOffer
      })
    },
    resetSelectedOffer (selectedAmount) {
      if (selectedAmount) {
        if (selectedAmount < this.minAmount) {
          selectedAmount = this.minAmount
        }

        if (selectedAmount > this.maxAmount) {
          selectedAmount = this.maxAmount
        }
      }

      this.selectOffer(this.offers[0] || null)
      this.amount = selectedAmount || this.selectedOffer?.amount || 0
    },
    matchOfferWithPeriod () {
      const matchingfOffer = this.findMatchingOffer(this.offers, this.flowData.period)
      if (matchingfOffer) {
        this.selectOffer(matchingfOffer)
      }
    },
    calculateContinue () {
      this.allowToContinue = this.offers.reduce((hasInterest, offer) => hasInterest && !!offer.annualInterestRate, true)
    },
    onAmountChanged () {
      if (Number(this.amount) < this.minAmount) {
        this.resetSelectedOffer(this.minAmount)
      }
    },
    getInitialPaymentFrequencyCode () {
      const validFrequencies = this.flowData.validPaymentFrequencies.filter(frequency => {
        return this.flowData.accountDefaults.some(offer => offer.interestPaymentFrequencyCode === frequency)
      })

      if (this.flowData.paymentFrequencyCode && validFrequencies.includes(this.flowData.paymentFrequencyCode)) {
        return this.flowData.paymentFrequencyCode
      }

      return validFrequencies[0]
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany']),
    translations () {
      const common = {
        title: this.$gettext('Offer page title'),
        howMuch: this.$gettext('How much you want to save?'),
        whichOffer: this.$gettext('Which is the best expected profit for you?'),
        differentPlan: this.$gettext('Try out different plan'),
        noOffers: this.$pgettext('no_deposit_offers', 'Sorry, we don\'t have new deposit offers at the moment.'),
        continue: this.$gettext('I want this plan')
      }

      if (this.isCompany) {
        return {
          ...common,
          title: this.$pgettext('corporate_offer_page', 'Offer page title'),
          howMuch: this.$pgettext('corporate_offer_page', 'How much you want to save?'),
          whichOffer: this.$pgettext('corporate_offer_page', 'Which is the best expected profit for you?'),
          noOffers: this.$pgettext('corporate_no_deposit_offers', 'Sorry, we don\'t have new deposit offers at the moment.'),
          continue: this.$pgettext('corporate_offer_page', 'I want this plan')
        }
      }

      return common
    },
    currency () {
      return getCurrency(this.flowData.accountType.restrictedCurrencyCode)
    },
    minAmount () {
      return (this.flowData.limits[this.paymentFrequencyCode] || {}).minAmount
    },
    maxAmount () {
      return (this.flowData.limits[this.paymentFrequencyCode] || {}).maxAmount
    },
    hasOffers () {
      return this.minAmount !== undefined && this.maxAmount !== undefined
    },
    locale () {
      return this.flowData.locale
    }
  },
  watch: {
    paymentFrequencyCode (value) {
      this.offers = this.filterAndSortOffers(this.flowData.accountDefaults, value)
      this.resetSelectedOffer(this.amount)
    }
  }
}
</script>

<style scoped lang="scss">
  .payment-frequency {
    &__container {
      margin: 0 auto;
      background-color: $white;
      width: 100%;

      @media (min-width: $desktop-view-breaking-point + 1) {
        padding: 0;
      }
    }
  }

  .offer-page {
    &__main-container {
      margin: 0 auto;
      padding: 0;
      max-width: 380px;
    }

    &__offer-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      margin: 0 auto;
      padding: 0;
      max-width: 380px;

      h4 {
        font-family: $max-pro-demi-bold;
        text-align: center;
        color: $navy;
        margin-top: 50px;
        margin-bottom: 0;
        width: 100%;
      }
    }

    &__slider {
      width: 100%;
      margin-top: 30px;
    }

    &__which-offer {
      font-family: $gotham-medium;
      font-size: $mobile-tiny-font-size;
      color: $gray-90;
      text-transform: uppercase;
      margin-top: 11px;

      @media (min-width: $desktop-view-breaking-point + 1) {
        font-size: $tiniest-font-size;
      }
    }

    &__different-plan {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 70px;

      @media (min-width: $desktop-view-breaking-point + 1) {
        margin-bottom: 60px;
      }

      svg {
        margin-right: 13px;
      }

      &-link {
        font-family: $gotham-book;
        font-size: $font-size-small;
      }
    }
  }
</style>
