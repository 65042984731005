<template>
  <bb-box
    class="
        d-flex flex-column flex-md-row justify-content-md-between align-items-center
        p-20 p-md-25 border-width-1 border-gray-20 bg-white m-t-15
      "
  >
    <div class="d-flex flex-column align-items-center align-items-md-start">
      <p class="f-gotham-medium f-small f-color-navy f-align-center f-align-md-left">
        {{ translations.title }}
      </p>
      <p class="m-t-5 f-smallest f-color-gray-80">
        {{ translations.description }}
      </p>
    </div>
    <bb-button
      data-testid="create-predefined-payment"
      color="black"
      size="sm"
      :label="translations.buttonText"
      class="m-t-15 m-t-md-0"
      @click="$emit('create')"
    />
  </bb-box>
</template>

<script>
export default {
  name: 'create-predefined-payments-banner',
  computed: {
    translations () {
      return {
        title: this.$pgettext('predefined_payment', 'You dont have any predefined european payment'),
        description: this.$pgettext('predefined_payment', 'Create one for quick payments inside Europe'),
        buttonText: this.$pgettext('predefined_payment', 'Add new payment')
      }
    }
  }
}
</script>
