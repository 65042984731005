import { isNil, isString } from 'lodash'
import { PAYMENT_DESCRIPTION_MAX_LENGTH, PAYMENT_REFERENCE_MAX_LENGTH } from '@/modules/account/views/payments/const'
import { CountryChannel } from '@bigbank/dc-common/config'
import { EEValidator } from '@bigbank/dc-common/validators/ee/EEValidator.class'
import { BeneficiaryValidationResultCode } from '@bigbank/dc-common/clients/http/account/account.enums'
import isValidReferenceNumber from '@bigbank/dc-common/validators/reference-number.validator'
import { currencyCodeToSymbol } from '@/plugins/currencyFormatters'
import { mapActions, mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import api from '@/modules/account/api'
import iban from 'iban'
import { useAccountStore } from '@/modules/account/store/accountStore'
import { usePredefinedPaymentStore } from '@/modules/account/store/predefinedPaymentStore'

const eeValidator = new EEValidator()
const isValidDescriptionLength = value => isString(value) && value.length <= PAYMENT_DESCRIPTION_MAX_LENGTH

export default {
  data () {
    return {
      predefinedPaymentId: null,
      isValidationLoading: false,
      prefilledData: null,
      isValid: false,
      loaders: {
        accounts: false,
        transactionDetails: false,
        predefinedPayment: false,
        submit: false
      },
      beneficiaryValidation: {
        isLoading: false,
        useLastError: false,
        isFormSubmitting: false,
        bypassNameValidation: false,
        errorMessage: {
          iban: null,
          name: null
        }
      },
      form: {
        amount: null,
        accountId: null,
        recipientName: '',
        recipientIban: '',
        referenceNumber: '',
        description: ''
      }
    }
  },
  watch: {
    // This is needed to trigger bb-form validation
    'form.recipientName' () {
      this.$validator.fields.length > 0 && this.$validator.validate('recipientName')
    },
    async 'form.recipientIban' () {
      this.$validator.fields.length > 0 && await this.$validator.validate('recipientIban')
      if (this.form.referenceNumber.length > 0 && this.form.recipientIban.length > 0) {
        this.$validator.fields.length > 0 && await this.$validator.validate('referenceNumber')
      }
    },
    async 'form.referenceNumber' () {
      this.$validator.fields.length > 0 && await this.$validator.validate('description')
      this.$validator.fields.length > 0 && await this.$validator.validate('referenceNumber')
    },
    async 'form.description' (value) {
      this.$validator.fields.length > 0 && await this.$validator.validate('description')
      this.$validator.fields.length > 0 && await this.$validator.validate('referenceNumber')
    },
    prefilledData: {
      immediate: true,
      handler () {
        this.prefillForm()
      }
    },
    predefinedPaymentId: {
      immediate: true,
      handler: async function (newId, oldId) {
        if (!isNil(newId) && newId !== oldId && Number(newId) > 0) {
          this.getPredefinedPayment()
        }
      }
    },
    $route: {
      immediate: true,
      handler (to) {
        if (to.query.predefinedPaymentId) {
          this.predefinedPaymentId = to.query.predefinedPaymentId
        }
      }
    },
    paymentAccounts: {
      immediate: true,
      handler () {
        this.prefillForm()
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'channel', 'currency', 'featureFlags']),
    ...mapState(useAccountStore, ['paymentAccounts', 'paymentAccountsLoading']),
    accountOptions () {
      if (!this.paymentAccounts) {
        return []
      }

      return this.paymentAccounts.map(account => ({
        text: account.iban,
        value: account.id
      }))
    },
    isLoading () {
      return Object.values(this.loaders).some(isLoading => isLoading) || this.paymentAccountsLoading
    },
    mixinTranslations () {
      return {
        errors: {
          descriptionOrReference: this.$pgettext('payment_form', 'Insert payment description or reference'),
          descriptionLength: this.$pgettext('payment_form', 'Entered description is faulty'),
          descriptionSpaceCannotBeFirstChar: this.$pgettext('payment_form', 'Space cannot be the first character'),
          descriptionCanContainOnlyFollowingsCharacters: this.$pgettext('payment_form', 'Description can contain only following characters: a-z 1-9 š ž õ ä ö ü / - ! ? : ( ) . , \' +'),
          referenceInvalid: this.$pgettext('payment_form', 'Reference is invalid'),
          beneficiary: {
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNameMismatch]: this.$pgettext(
              'payment_form',
              'Entered name is incorrect, please review'
            ),
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNonExistingAccount]: this.$gettext(
              'payment_form',
              'Entered IBAN does not exist'
            ),
            INVALID_IBAN: this.$pgettext(
              'payment_form',
              'Entered IBAN is incorrect, please review'
            ),
            SENDER_MATCHES_RECIPIENT_IBAN: this.$pgettext(
              'payment_form',
              'Sender and recipient accounts are the same'
            ),
            NAME_LENGTH: this.$pgettext(
              'payment_form',
              'Entered name is faulty'
            )
          }
        }
      }
    },
    maxInputLengths () {
      return {
        reference: PAYMENT_REFERENCE_MAX_LENGTH,
        description: PAYMENT_DESCRIPTION_MAX_LENGTH
      }
    },
    currencySymbol () {
      return currencyCodeToSymbol(this.selectedAccount?.currencyCode ?? this.currency)
    },
    isEstonianIban () {
      return (this.form?.recipientIban ?? '').startsWith(CountryChannel.EE)
    },
    button () {
      return {
        disabled: !this.isValid || this.isLoading || this.isValidationLoading,
        loading: this.isLoading || this.isValidationLoading
      }
    },
    selectedAccount () {
      return this.paymentAccounts?.find(account => account.id === this.form.accountId)
    }
  },
  methods: {
    ...mapActions(useAccountStore, ['getAccountsForPayment']),
    ...mapActions(usePredefinedPaymentStore, ['getPredefinedById']),
    onProgress (value) {
      this.isValid = value === 1
    },
    formatIban (inputStr) {
      return inputStr.replace(/\s/g, '').toUpperCase()
    },
    async getAccounts (forceReload = false) {
      await this.getAccountsForPayment(forceReload)
      if (this.paymentAccounts?.length === 1) {
        this.form.accountId = this.paymentAccounts[0].id
      }
    },
    async getPredefinedPayment () {
      this.loaders.predefinedPayment = true
      this.prefilledData = await this.getPredefinedById(this.predefinedPaymentId)
      this.loaders.predefinedPayment = false
    },
    async validateBeneficiary (fieldName) {
      if (this.beneficiaryValidation.isFormSubmitting) {
        return true
      }

      try {
        this.isValidationLoading = true

        if (this.beneficiaryValidation.bypassNameValidation) {
          this.beneficiaryValidation.bypassNameValidation = false

          return true
        }

        if (this.beneficiaryValidation.useLastError) {
          this.beneficiaryValidation.useLastError = false
          return false // throw validation error as last result contains error for this field
        }

        const isIbanValid = iban.isValid(this.form.recipientIban)

        if (
          !this.form.recipientName ||
          !this.form.recipientIban ||
          !isIbanValid
        ) {
          return true
        }

        this.beneficiaryValidation.errorMessage.iban = null
        this.beneficiaryValidation.errorMessage.name = null
        this.beneficiaryValidation.isLoading = true

        const result = await api.validateBeneficiary({
          iban: this.form.recipientIban,
          beneficiary: this.form.recipientName
        })

        if (
          result.matched === false &&
          Object.keys(this.mixinTranslations.errors.beneficiary).includes(result.resultCode)
        ) {
          const resultCodeToFieldMap = {
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNameMismatch]: 'recipientName',
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNonExistingAccount]: 'recipientIban',
            INVALID_IBAN: 'recipientIban'
          }

          const message = this.mixinTranslations.errors.beneficiary[result.resultCode]
          const errorField = resultCodeToFieldMap[result.resultCode]
          if (errorField === 'recipientName') {
            this.beneficiaryValidation.errorMessage.name = message
          } else {
            this.beneficiaryValidation.errorMessage.iban = message
          }

          if (errorField !== fieldName) {
            // run validation for other field, but use the validation result of this one,
            // in order to not create double requests
            this.beneficiaryValidation.useLastError = true
            await this.$validator.validate(errorField)
          }

          // if error is irrelevant to the fieldName provided, bypass validation
          return errorField !== fieldName
        } else if (isString(result.systemFullName)) {
          this.form.recipientName = result.systemFullName
          // trigger validation again for name field in order to update field status after name is autocompleted
          if (fieldName === 'recipientIban') {
            this.beneficiaryValidation.bypassNameValidation = true
            this.beneficiaryValidation.errorMessage.name = null
            this.$validator.validate('recipientName')
          }
        }

        return true
      } finally {
        this.beneficiaryValidation.isLoading = false
        this.isValidationLoading = false
      }
    },
    prefillForm () {
      const details = this.prefilledData

      if (!details || !this.paymentAccounts) {
        return
      }

      if (this.form.name !== undefined && details.name) {
        this.form.name = details.name
      }
      const fromIban = details.accountIban ?? details.fromIban
      if (fromIban) {
        this.form.accountId = this.paymentAccounts.find(account => account.iban === fromIban)?.id
      }
      if (details.description) {
        this.form.description = details.description
      }
      if (details.referenceNumber) {
        this.form.referenceNumber = details.referenceNumber
      }
      if (details.counterpartyName ?? details.recipientName) {
        this.form.recipientName = details.counterpartyName ?? details.recipientName
      }
      if (details.counterpartyIban ?? details.recipientIban) {
        this.form.recipientIban = details.counterpartyIban ?? details.recipientIban
      }
      if (details.amount) {
        this.form.amount = details.amount
      }
    }
  },
  created () {
    this.$validator.extend('descriptionLength', {
      getMessage: () => this.mixinTranslations.errors.descriptionLength,
      validate: isValidDescriptionLength
    })
    this.$validator.extend('descriptionSpaceCannotBeFirstChar', {
      getMessage: () => this.mixinTranslations.errors.descriptionSpaceCannotBeFirstChar,
      validate: (value) => isString(value) && !value.startsWith(' ')
    })
    this.$validator.extend('descriptionCanContainOnlyFollowingsCharacters', {
      getMessage: () => this.mixinTranslations.errors.descriptionCanContainOnlyFollowingsCharacters,
      validate: (value) => this.channel === CountryChannel.EE
        ? isString(value) && eeValidator.isPaymentDescriptionContainsOnlyValidSymbols(value)
        : isValidDescriptionLength
    })

    this.$validator.extend('atLeastOneFilled', {
      getMessage: () => {
        return this.mixinTranslations.errors.descriptionOrReference
      },
      validate: (value, [target]) => {
        const isValid = (!isNil(value) && value.length > 0) || (!isNil(target) && target.length > 0)

        return {
          valid: isValid,
          data: {
            required: !target
          }
        }
      }
    }, {
      computesRequired: true,
      hasTarget: true
    })

    this.$validator.extend('referenceCorrectness', {
      getMessage: () => this.mixinTranslations.errors.referenceInvalid,
      validate: value => {
        if (this.form.description.length > 0 && this.form.referenceNumber.trim().length === 0) {
          return true
        }

        return isValidReferenceNumber(this.channel, value, {
          strict: this.isEstonianIban && this.featureFlags.enablePaymentReferenceNumberStrictValidation
        })
      }
    })

    this.$validator.extend('beneficiary-name', {
      getMessage: () => this.beneficiaryValidation.errorMessage.name,
      validate: async () => {
        return await this.validateBeneficiary('recipientName')
      }
    })
    this.$validator.extend('beneficiary-name-length', {
      getMessage: () => this.mixinTranslations.errors.beneficiary.NAME_LENGTH,
      validate: (value) => {
        return isString(value) && value.length <= 140
      }
    })

    this.$validator.extend('iban', {
      getMessage: () => this.mixinTranslations.errors.beneficiary.INVALID_IBAN,
      validate: (value) => iban.isValid(value)
    })
    this.$validator.extend('beneficiary-iban', {
      getMessage: () => this.beneficiaryValidation.errorMessage.iban,
      validate: async () => {
        return await this.validateBeneficiary('recipientIban')
      }
    })
    this.$validator.extend('unique-beneficiary-iban', {
      getMessage: () => this.mixinTranslations.errors.beneficiary.SENDER_MATCHES_RECIPIENT_IBAN,
      validate: () => {
        return this.form.accountId && this.form.recipientIban !== this.selectedAccount.iban
      }
    })
  }
}
