<template>
  <signing-modal-wrapper
      :visible="visible"
      @close="$emit('close', $event)"
      :showLoader="isLoading">
    <template slot="heading"><translate>{{translations.title}}</translate></template>
    <p v-if="showErrorMessage" class="error">
      <signing-error-messages
          :code="errorCode"
          :type="signingMethod"
          :visible="showErrorMessage"
          :data="errorData"
          @close="closeModal"
      />
    </p>
    <signature-verification-modal
        :visible="isModalVisible"
        @close="closeModal"
        :challengeId="challengeId"
        :errorCode="errorCode"
        :type="signingMethod"/>
  </signing-modal-wrapper>
</template>

<script>
import { SIGNING_METHODS } from '@bigbank/dc-common/enums/loginAndSigningMethods'
import SigningErrorMessages from './SigningErrorMessages'
import SignatureVerificationModal from './SignatureVerificationModal'
import SigningModalWrapper from './SigningModalWrapper'
import { isFunction, isNull, isString } from 'lodash'
import isUndefined from 'lodash/isUndefined'
import { VERIFICATION_METHODS } from '@/const'

export default {
  name: 'smart-id-signing',
  components: { SignatureVerificationModal, SigningErrorMessages, SigningModalWrapper },
  data () {
    return {
      isModalVisible: false,
      challengeId: '',
      isLoading: false,
      errorCode: null,
      errorData: null,
      signingMethod: SIGNING_METHODS.SMART_ID
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    startSigning: {
      required: true
    },
    completeSigning: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$gettextInterpolate(
          this.$pgettext('signing_form_header_smartid', 'Signature with Smart-ID')
        ),
        loading: this.$pgettext('loading', 'Loading please wait...')
      }
    },
    showErrorMessage () {
      return !isNull(this.errorCode)
    }
  },
  methods: {
    setErrorCode (code) { this.errorCode = code },
    toggleModalVisibility (flag) { this.isModalVisible = flag },
    setLoading (flag) { this.isLoading = flag },
    setChallengeId (challengeId) { this.challengeId = challengeId },
    async pollStatus (delayMs = VERIFICATION_METHODS.SMART_ID.POLL_DELAY_MS) {
      if (!this.isModalVisible) return

      try {
        const response = await this.completeSigning()

        if (response.status === VERIFICATION_METHODS.SMART_ID.STATUS.COMPLETED_OK) {
          this.toggleModalVisibility(false)
          this.$emit('signingSuccessful')
        } else if (response.status === VERIFICATION_METHODS.SMART_ID.STATUS.RUNNING) {
          setTimeout(() => this.pollStatus(), delayMs)
        } else {
          throw response
        }
      } catch (response) {
        if (this.isResponseErrored(response)) {
          this.setErrorCode(!response ? 'DEFAULT' : (response?.errCode ?? response.err_code))
        }
        this.$emit('signingError', response)
      }
    },
    async startSigningProcess (onChallengeIdReceived) {
      this.setLoading(true)
      this.setErrorCode(null)

      try {
        const response = await this.startSigning()

        if (isString(response.challengeId)) {
          this.setChallengeId(response.challengeId)
          this.toggleModalVisibility(true)
          this.setLoading(false)
          isFunction(onChallengeIdReceived) && await onChallengeIdReceived()
        } else {
          throw response
        }
      } catch (response) {
        this.setLoading(false)

        if (this.isResponseErrored(response)) {
          this.setErrorCode(!response ? 'DEFAULT' : (response?.errCode ?? response.err_code))
        }
      }
    },
    isResponseErrored (response) {
      return !(isUndefined(response.errCode) && isUndefined(response.err_code))
    },
    closeModal () {
      this.toggleModalVisibility(false)
      this.setErrorCode(null)
      this.$emit('close')
    }
  },
  mounted () {
    this.startSigningProcess(async () => {
      await this.pollStatus()
    })
  }
}
</script>

<style lang="scss" scoped>

.error {
  color: $red;
  font-family: $gotham-medium;
  margin-top: 20px;
  text-align: center;
  font-size: $font-size-smallest;
}

.loading {
  font-family: $gotham-medium;
  margin-top: 20px;
  text-align: center;
  font-size: $font-size-small;
}
</style>
